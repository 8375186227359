import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CreditCardForm from './CreditCardForm';
import Box from "components/box/Box";
import AccountIcon from "components/icon/AccountIcon";
import CreditCardIcon from "components/icon/CreditCardIcon";
import './assets/styles/CreditCardPage.scss';

const CreditCardPage = (
  {
    name,
    email,
    phone,
    apartment,
    stripeClientSecret,
    stripeApiKey,
    onPaymentMethodReady,
  }
) => {
  const [stripePromise, setStripePromise] = useState();

  useEffect(
    () => {
      setStripePromise(loadStripe(stripeApiKey));
    },
    []
  );

  return (
    <div className={'p-credit-card'}>
      <Box
        image={<AccountIcon/>}
        title={'Account details'}
      >
        <div className={'p-credit-card__details-grid'}>
          <div className={'p-credit-card__details-grid__row'}>
            <div>Name</div>
            <div>{name}</div>
          </div>
          <div className={'p-credit-card__details-grid__row'}>
            <div>Email</div>
            <div>{email}</div>
          </div>
          <div className={'p-credit-card__details-grid__row'}>
            <div>Phone</div>
            <div>{phone}</div>
          </div>
          <div className={'p-credit-card__details-grid__row'}>
            <div>Apartment</div>
            <div>{apartment}</div>
          </div>
        </div>
        <div className={'p-credit-card__details-text'}>
          <p>Activate your account to use services in your apartment, chat with the concierge and find a carefully curated list of hot spots.</p>
          <p>We offer services like housekeeping, breakfast and dry cleaning. Without a valid credit card you won’t be able to activate your account and log in.</p>
        </div>
      </Box>
      <Box
        image={<CreditCardIcon/>}
        title={'Credit Card'}
      >
        {!stripePromise && (<span>Loading payment details ...</span>)}
        {stripePromise && (
          <>
            <Elements stripe={stripePromise}>
              <CreditCardForm
                name={name}
                stripeClientSecret={stripeClientSecret}
                onPaymentMethodReady={onPaymentMethodReady}
              />
            </Elements>
            <div className={'p-credit-card__details-text'}>
              <small>
                We only charge the services you use (rent is charged differently).
              </small>
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default CreditCardPage;