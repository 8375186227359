import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/app/App';
import './assets/styles/base.scss';

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
