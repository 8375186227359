import React, {useEffect, useState} from 'react';
import Box from "components/box/Box";
import PasswordField from "components/password/PasswordField";
import AccountIcon from "components/icon/AccountIcon";
import './assets/styles/PasswordPage.scss';

const PasswordPage = (
  {
    onSubmit
  }
) => {
  const [disabled, setDisabled]               = useState(false);
  const [valid, setValid]                     = useState('');
  const [password, setPassword]               = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(
    () => {
      const check = !/\s/.test(password) && /\d/.test(password) && password.length >= 6;
      setValid(check && password === confirmPassword);
    },
    [password, confirmPassword]
  );

  return (
    <div className={'p-password-page'}>
      <Box
        image={<AccountIcon/>}
        title={'Account password'}
      >
        <div className={'p-password-page__details-text'}>
          <p>Please fill in a secure password for your account to complete the activation.</p>
        </div>
        <div className={'p-password-page__details-grid'}>
          <div className={'p-password-page__details-grid__row'}>
            <label htmlFor={'password'}>Password</label>
            <PasswordField
              id={'password'}
              onChange={(value) => {
                setPassword(value);
              }}
            />
            <small>
              Password should be at least 6 characters long and should contain 1 lower and 1 capital and 1 number
            </small>
          </div>
          <div className={'p-password-page__details-grid__row'}>
            <label htmlFor={'confirmPassword'}>Retype password</label>
            <PasswordField
              id={'confirmPassword'}
              onChange={(value) => {
                setConfirmPassword(value);
              }}
            />
          </div>
        </div>
        <div>
          <button
            disabled={valid !== true || disabled}
            onClick={() => {
              setDisabled(true);
              onSubmit(password);
            }}
          >
            Complete activation
          </button>
        </div>
      </Box>
    </div>
  );
}

export default PasswordPage;