import React from "react";

const CreditCardIcon = ({...props}) => (
  <svg width="20px" height="15px" viewBox="0 0 20 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Create-booking" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Create-booking---new" transform="translate(-390.000000, -852.000000)" fill="#000000">
        <g id="Page-1" transform="translate(390.000000, 852.000000)">
          <path d="M0.0485853659,1.45025 L0.0485853659,13.5255 C0.0485853659,14.2257 0.58497561,14.7755 1.26809756,14.7755 L18.732,14.7755 C19.415122,14.7755 19.9515122,14.2257 19.9515122,13.5255 L19.9515122,1.45025 C19.9515122,0.75005 19.415122,0.20025 18.732,0.20025 L1.26809756,0.20025 C0.58497561,0.20025 0.0485853659,0.77545 0.0485853659,1.45025 Z M18.732,14.29975 L1.26809756,14.29975 C0.853658537,14.29975 0.536390244,13.97455 0.536390244,13.54975 L0.536390244,4.70025 L19.4637073,4.70025 L19.4637073,13.54975 C19.4637073,13.95015 19.146439,14.29975 18.732,14.29975 Z M1.26809756,0.70025 L18.732,0.70025 C19.146439,0.70025 19.4637073,1.02545 19.4637073,1.45025 L19.4637073,4.17475 L0.536390244,4.17475 L0.536390244,1.45025 C0.536390244,1.04985 0.853658537,0.70025 1.26809756,0.70025 Z" id="Fill-1"/>
          <path d="M4.92658537,7.375 C3.85380488,7.375 2.97536585,8.2754 2.97536585,9.375 C2.97536585,10.4746 3.85380488,11.375 4.92658537,11.375 C5.99936585,11.375 6.87780488,10.4746 6.87780488,9.375 C6.87780488,8.2754 6.00031707,7.375 4.92658537,7.375 Z M4.92658537,10.875 C4.1215122,10.875 3.46317073,10.2002 3.46317073,9.375 C3.46317073,8.5498 4.1215122,7.875 4.92658537,7.875 C5.73165854,7.875 6.39,8.5498 6.39,9.375 C6.39,10.2002 5.73165854,10.875 4.92658537,10.875 Z" id="Fill-2"/>
          <polygon id="Fill-3" points="8.68341463 8.17475 17.3170732 8.17475 17.3170732 8.67475 8.68341463 8.67475"/>
          <polygon id="Fill-4" points="8.68341463 9.79975 17.3170732 9.79975 17.3170732 10.29975 8.68341463 10.29975"/>
        </g>
      </g>
    </g>
  </svg>
);

export default CreditCardIcon;