import React from "react";

const HutIcon = ({...props}) => (
  <svg width="90px" height="38px" viewBox="0 0 90 38" version="1.1" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-655.000000, -71.000000)" fill="currentColor">
        <g transform="translate(655.000000, 71.000000)">
          <path
            d="M56.4524447,24.7486694 C56.4524447,27.4521784 55.7146144,29.5325156 54.1901231,31.1154086 C52.6785102,32.7145186 50.7072067,33.4918435 48.1640625,33.4918435 C45.6198451,33.4918435 43.6488993,32.7147008 42.1310261,31.1084845 C40.6135106,29.5330623 39.8756803,27.4527251 39.8756803,24.7486694 L39.8756803,0 L35.15625,0 L35.15625,25.0985202 C35.15625,28.8421068 36.3827474,31.9709033 38.8124894,34.4083799 C41.2581506,36.791739 44.4044375,38 48.1640625,38 C51.9233298,38 55.0697956,36.791739 57.5270832,34.3974471 C59.9453776,31.96981 61.171875,28.8411957 61.171875,25.0985202 L61.171875,0 L56.4524447,0 L56.4524447,24.7486694 Z"
          />
          <polygon
            points="63.984375 0 63.984375 4.45989428 74.5665734 4.45989428 74.5665734 37.2962963 79.3679465 37.2962963 79.3679465 4.45989428 90 4.45989428 90 0"
          />
          <polygon
            points="21.4066246 10.0063719 4.60881939 19.7004123 4.60881939 0 0 0 0 37.2962963 4.60881939 37.2962963 4.60881939 25.0200014 21.4066246 15.325961 21.4066246 37.2962963 26.015625 37.2962963 26.015625 0 21.4066246 0"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HutIcon;