import React from "react";

const AccountIcon = (props) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Create-booking" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Create-booking---new" transform="translate(-390.000000, -556.000000)" fill="#000000">
        <g id="Page-1" transform="translate(390.000000, 556.000000)">
          <path d="M10,19.9163889 C15.4719444,19.9163889 19.9163889,15.4719444 19.9163889,10 C19.9163889,4.52805278 15.4719444,0.0836083333 10,0.0836083333 C4.52805556,0.0836083333 0.0836111111,4.52805278 0.0836111111,10 C0.0836111111,15.4719444 4.52805556,19.9163889 10,19.9163889 Z M3.44388889,16.6666667 C5.19411111,14.3891111 7.52694444,13.1391667 10,13.1391667 C12.4717778,13.1391667 14.7775,14.3891667 16.5561111,16.6666667 C14.8612222,18.3333333 12.5566667,19.3337778 10,19.3337778 C7.44466667,19.3337778 5.13888889,18.3333333 3.44388889,16.6666667 Z M10,0.639166667 C15.1672222,0.639166667 19.3608333,4.83416667 19.3608333,10 C19.3608333,12.4164444 18.4439444,14.6116667 16.9443889,16.2783333 C15.0835,13.889 12.6105,12.5836111 9.99994444,12.5836111 C7.38938889,12.5836111 4.91633333,13.8889444 3.0555,16.2783333 C1.55594444,14.6116667 0.639055556,12.4177778 0.639055556,10 C0.639055556,4.83277778 4.83294444,0.639166667 9.99988889,0.639166667 L10,0.639166667 Z" id="Fill-1"/>
          <path d="M10,10.6391667 C12.028,10.6391667 13.6663889,9.00072222 13.6663889,6.97277778 C13.6663889,4.94477778 12.0279444,3.30527778 10,3.30527778 C7.97205556,3.30527778 6.33361111,4.94372222 6.33361111,6.97166667 C6.33361111,8.99966667 7.97205556,10.6391667 10,10.6391667 Z M10,3.86083333 C11.722,3.86083333 13.1108333,5.24972222 13.1108333,6.97166667 C13.1108333,8.69366667 11.7219444,10.0825 10,10.0825 C8.27805556,10.0825 6.88916667,8.69361111 6.88916667,6.97166667 C6.88916667,5.27786111 8.27805556,3.86083333 10,3.86083333 Z" id="Fill-2"/>
        </g>
      </g>
    </g>
  </svg>
);

export default AccountIcon;