import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import './assets/styles/PasswordField.scss'

const EyeIcon = (props) => (
  <svg width="21px" height="12px" viewBox="0 0 21 12" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-268.000000, -91.000000)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(268.000000, 91.000000)">
          <path d="M17.8558791,2.92967819 C16.143897,1.59237187 13.4771557,0 10.5,0 C7.52284434,0 4.85610302,1.59237187 3.14412094,2.92967819 C2.0340918,3.78927904 1.01931125,4.7683854 0.117581187,5.84982122 L0,6 L0.117581187,6.15017878 C1.01931125,7.2316146 2.0340918,8.21072096 3.14412094,9.07032181 C4.85610302,10.4076281 7.52284434,12 10.5,12 C13.4771557,12 16.143897,10.4052443 17.8558791,9.07032181 C18.9659082,8.21072096 19.9806887,7.2316146 20.8824188,6.15017878 L21,6 L20.8824188,5.84982122 C19.9806887,4.7683854 18.9659082,3.78927904 17.8558791,2.92967819 Z M10.5,11.523242 C5.56159015,11.523242 1.37099664,6.89630513 0.606718925,6 C1.37099664,5.10369487 5.54512878,0.476758045 10.5,0.476758045 C15.4548712,0.476758045 19.6290034,5.10369487 20.3932811,6 C19.6290034,6.89630513 15.4525196,11.523242 10.5,11.523242 Z"/>
          <path d="M10.4436232,2.00008999 C8.51827199,2.0311097 6.97960844,3.61166287 7.00020437,5.53725482 C7.02080031,7.46284678 8.59291883,9.01012385 10.518493,8.99995012 C12.4440673,8.98977638 13.9997502,7.42597364 14,5.50027155 C13.9826346,3.55236692 12.3914545,1.98632944 10.4436232,2.00008999 Z M10.4436232,8.54037769 C8.76624339,8.5093476 7.42906966,7.12894944 7.45131468,5.4513421 C7.4735597,3.77373477 8.84686488,2.42928125 10.5244777,2.44274293 C12.2020905,2.4562046 13.5536459,3.82252324 13.5489693,5.50027155 C13.5316143,7.19906762 12.142363,8.56316806 10.4436232,8.54939878 L10.4436232,8.54037769 Z"/>
        </g>
      </g>
    </g>
  </svg>
);

/**
 *
 * @returns {*}
 * @constructor
 */
const PasswordField = (
  {
    onChange,
    ...props
  }
) => {
  const [focus, setFocus]       = useState(false);
  const [password, setPassword] = useState('');
  const [type, setType]         = useState('password');
  const ref                     = React.createRef();

  return (
    <div
      className={classNames('c-password-field', {
        'c-password-field__focus'  : focus,
        'c-password-field__visible': type === 'text'
      })}
    >
      <input
        {...props}
        type={type}
        ref={ref}
        value={password}
        onChange={(event) => {
          const value = event.target.value;
          setPassword(value);
          onChange(value);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
      />
      <button
        className={'c-password-field__toggle'}
        onClick={() => {
          setType(type === 'text' ? 'password' : 'text');
          ref.current.focus();
        }}
      >
        <EyeIcon width="18px" height="18px"/>
      </button>
    </div>
  );
}

export default PasswordField;