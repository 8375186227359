import React from 'react';
import './assets/styles/Box.scss';

const Box = (
  {
    title,
    image,
    children
  }
) => (
  <div className={'c-box'}>
    {(title || image) && (
      <div className={'c-box__header'}>
        {image}
        <span>
          {title}
        </span>
      </div>
    )}
    {children}
  </div>
);

export default Box;

