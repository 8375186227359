import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import './assets/styles/CreditCardForm.scss';

const CreditCardForm = (
  {
    name,
    stripeClientSecret,
    onPaymentMethodReady
  }
) => {
  const [error, setError]       = useState('');
  const [disabled, setDisabled] = useState(true);
  const stripe                  = useStripe();
  const elements                = useElements();

  const onSubmit = async (event) => {
    // we don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // stripe.js has not yet loaded.
      // make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDisabled(true);

    const result = await stripe.confirmCardSetup(stripeClientSecret, {
      payment_method: {
        card           : elements.getElement(CardElement),
        billing_details: {
          name: name,
        },
      }
    });

    if (result.error) {
      setError(result.error.message);
      setDisabled(false);
    } else {
      onPaymentMethodReady(result.setupIntent.payment_method);
    }
  };

  return (
    <form onSubmit={onSubmit} className={'c-credit-card-form'}>
      {(error && (
        <p className={'c-credit-card-form__error'}>{error}</p>
      ))}
      <CardElement
        onChange={(event) => {
          setError('');
          setDisabled(!(event && event.complete === true));
        }}
      />
      <button disabled={disabled}>
        Verify Card
      </button>
    </form>
  );
}

export default CreditCardForm;