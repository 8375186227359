import 'whatwg-fetch';
import Application from 'constants/Application';

const API_URL                          = `${Application.APPLICATION_API_HOST}${Application.APPLICATION_API_URL}`;
const API_VALIDATE_ACTIVATION_CODE_URL = `${API_URL}residents/{residentId}/validateActivation?c={activationCode}`;
const API_ACTIVATE_URL                 = `${API_URL}residents/{residentId}/activate?c={activationCode}`;
const METHOD_GET                       = 'GET';
const METHOD_POST                      = 'POST';

class Api {
  /**
   * Perform a fetch request with the given method to the given url, body and headers
   * can be attached optionally.
   * Note: header content-type will by default by overwritten with application/json.
   * @param {string} method
   * @param {string} url
   * @param {object} body
   * @param {object} headers (Optional)
   * @returns {Promise<unknown>}
   */
  request(method, url, body = undefined, headers = {}) {
    return new Promise((resolve, reject) => {
      const config = {
        redirect: 'follow',
        method  : method,
      };

      if (body !== undefined) {
        config['body'] = JSON.stringify(body);
      }

      config['headers'] = new Headers(headers || {});
      config['headers'].append('Content-Type', 'application/json');
      config['mode'] = 'cors';

      fetch(url, config)
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  };

  /**
   * Validate the given activation code for the resident.
   * @param {string} activationCode
   * @param {string} residentId
   * @returns {Promise<unknown>}
   */
  validateActivationCodeForResident = (activationCode, residentId) => this.request(
    METHOD_GET,
    API_VALIDATE_ACTIVATION_CODE_URL
      .replace('{activationCode}', activationCode)
      .replace('{residentId}', residentId)
  );

  /**
   * Complete the activation for the given resident, will set the password for the resident.
   * @param {string} activationCode
   * @param {string} residentId
   * @param {string} password
   * @returns {Promise<unknown>}
   */
  completeActivationForResident = (activationCode, residentId, password) => this.request(
    METHOD_POST,
    API_ACTIVATE_URL
      .replace('{activationCode}', activationCode)
      .replace('{residentId}', residentId),
    {
      password: {
        first : password,
        second: password
      }
    }
  );
}

export default new Api();