import React, {useEffect, useState} from 'react';
import Api from 'utils/api';
import ApplicationConstants from 'constants/Application';
import Box from "components/box/Box";
import CreditCardPage from "pages/CreditCard/CreditCardPage";
import PasswordPage from "pages/Password/PasswordPage";
import CompletePage from "pages/Complete/CompletePage";
import HutIcon from "components/icon/HutIcon";
import './assets/styles/Onboard.scss';

/**
 * Validate the activation code for the given resident.
 *
 * @param {string} queryResidentId
 * @param {string} queryActivationCode
 * @param {function} setCurrentStep
 * @param {function} setPersonalDetails
 */
const validateActivationCode = (queryResidentId, queryActivationCode, setCurrentStep, setPersonalDetails) => {
  if (!queryResidentId || !queryActivationCode) {
    setCurrentStep(Onboard.STEPS.ACTIVATION_MISSING_CODE);
    return;
  }

  Api.validateActivationCodeForResident(queryActivationCode, queryResidentId)
    .then(result => {
      const status = result && result.status;
      if (status && status === 'success') {
        const data = result && result.data;

        // make sure we have a stripe client secret.
        if (!data.stripeClientSecret) {
          setCurrentStep(Onboard.STEPS.ACTIVATION_FAILED);
        } else {
          setPersonalDetails(
            {
              name              : data.name,
              email             : data.email,
              phone             : data.phoneNumber,
              apartment         : data && data.contract && data.contract.apartment && data.contract.apartment.name,
              stripeClientSecret: data.stripeClientSecret
            }
          );
          setCurrentStep(Onboard.STEPS.ACTIVATION_STEP_CREDIT_CARD_DETAILS);
        }
      } else if (status && status === 'error') {
        setCurrentStep(Onboard.STEPS.ACTIVATION_CODE_EXPIRED_OR_INVALID);
      } else {
        setCurrentStep(Onboard.STEPS.ACTIVATION_FAILED);
      }
    })
    .catch(error => {
      setCurrentStep(Onboard.STEPS.ACTIVATION_FAILED);
    });
};

/**
 * Complete the activation for the resident and set the password given.
 *
 * @param {string} queryResidentId
 * @param {string} queryActivationCode
 * @param {function} setCurrentStep
 * @param {string} password
 */
const completeActivation = (queryResidentId, queryActivationCode, setCurrentStep, password) => {
  Api.completeActivationForResident(queryActivationCode, queryResidentId, password)
    .then(result => {
      setCurrentStep(Onboard.STEPS.ACTIVATION_STEP_COMPLETED);
    })
    .catch(error => {
      setCurrentStep(Onboard.STEPS.ACTIVATION_STEP_COMPLETED);
    });
};

/**
 * Onboard component
 *
 * @return {*}
 * @constructor
 */
const Onboard = () => {
  const [personalDetails, setPersonalDetails] = useState({});
  const [step, setStep]                       = useState(Onboard.STEPS.LOADING);
  const [steps, setSteps]                     = useState([]);

  // we can extract parameters from window location
  const search = window.location.search;
  const params = new URLSearchParams(search);

  // region support query parameters
  const queryResidentId     = parseInt(params.get('id'));
  const queryActivationCode = params.get('c');
  // endregion

  useEffect(
    () => validateActivationCode(queryResidentId, queryActivationCode, setCurrentStep, setPersonalDetails),
    [queryResidentId, queryActivationCode]
  )

  // region functions

  /**
   * Assign the current wizard step to activate, also keep track of all steps that were assigned
   * so we can navigate back and forth.
   * @param {number} currentStep
   * @param {array} currentSteps (Optional)
   */
  const setCurrentStep = (currentStep, currentSteps = steps) => {
    if (currentSteps.length === 0 || currentSteps[steps.length - 1] !== currentStep) {
      setSteps([...currentSteps, currentStep]);
    }
    setStep(currentStep);
  };

  // endregion

  // region determine which step needs to be rendered.
  let pageTitle     = '';
  let pageComponent = '';

  switch (step) {
    case Onboard.STEPS.LOADING:
      pageTitle     = 'Activation details are being validated';
      pageComponent = (
        <Box>
          <p>
            Your activation details are being validated, please wait a moment ...
          </p>
        </Box>
      );
      break;
    case Onboard.STEPS.ACTIVATION_FAILED:
      pageTitle     = 'Activation details are invalid';
      pageComponent = (
        <Box>
          <p>
            We couldn't validate your activation details, this could be because we are having technical difficulties or that
            something wen't wrong with sending you your activation details.
          </p>
          <p>
            Please contact us and we will help you to activate your account as soon as possible, we are sorry for the inconvenience.
          </p>
        </Box>
      );
      break;
    case Onboard.STEPS.ACTIVATION_MISSING_CODE:
      pageTitle     = 'Activation details are missing';
      pageComponent = (
        <Box>
          <p>
            The details that we need to activate are missing.
          </p>
          <p>
            Please contact us and we will send you a new activation e-mail, we are sorry for the inconvenience.
          </p>
        </Box>
      );
      break;
    case Onboard.STEPS.ACTIVATION_CODE_EXPIRED_OR_INVALID:
      pageTitle     = 'Activation invalid or expired';
      pageComponent = (
        <Box>
          <p>
            Your activation code was invalid or has been expired.
          </p>
          <p>
            Please contact us and we will send you a new activation e-mail, we are sorry for the inconvenience.
          </p>
        </Box>
      );
      break;
    case Onboard.STEPS.ACTIVATION_STEP_CREDIT_CARD_DETAILS:
      pageTitle     = 'Step 1/2 - Credit Card';
      pageComponent = (
        <CreditCardPage
          name={personalDetails && personalDetails.name}
          email={personalDetails && personalDetails.email}
          phone={personalDetails && personalDetails.phone}
          apartment={personalDetails && personalDetails.apartment}
          stripeClientSecret={personalDetails && personalDetails.stripeClientSecret}
          stripeApiKey={ApplicationConstants.APPLICATION_STRIPE_API_KEY}
          onPaymentMethodReady={(paymentMethod) => {
            setCurrentStep(Onboard.STEPS.ACTIVATION_STEP_PASSWORD);
          }}
        />
      );
      break;
    case Onboard.STEPS.ACTIVATION_STEP_PASSWORD:
      pageTitle     = 'Step 2/2 - Password';
      pageComponent = (
        <PasswordPage
          onSubmit={(password) => {
            completeActivation(queryResidentId, queryActivationCode, setCurrentStep, password);
          }}
        />
      );
      break;
    case Onboard.STEPS.ACTIVATION_STEP_COMPLETED:
      pageTitle     = '';
      pageComponent = (
        <CompletePage/>
      );
      break;
  }
  // endregion

  return (
    <div className={'c-onboard'}>
      {(pageTitle && (
        <>
          <HutIcon className={'c-onboard__logo'}/>
          <h1 className={'c-onboard__title'}>
            {pageTitle}
          </h1>
        </>
      ))}
      {pageComponent}
    </div>
  );
};

Onboard.STEPS = {
  LOADING                            : 'LOADING',
  ACTIVATION_MISSING_CODE            : 'ACTIVATION_MISSING_CODE',
  ACTIVATION_FAILED                  : 'ACTIVATION_FAILED',
  ACTIVATION_CODE_EXPIRED_OR_INVALID : 'ACTIVATION_CODE_EXPIRED_OR_INVALID',
  ACTIVATION_STEP_CREDIT_CARD_DETAILS: 'ACTIVATION_STEP_CREDIT_CARD_DETAILS',
  ACTIVATION_STEP_PASSWORD           : 'ACTIVATION_STEP_PASSWORD',
  ACTIVATION_STEP_COMPLETED          : 'ACTIVATION_STEP_COMPLETED',
}

export default Onboard;
