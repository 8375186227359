import React from 'react';
import Box from "components/box/Box";
import appleStoreBadge from './assets/images/apple-store-badge.png';
import googlePlayBadge from './assets/images/google-play-badge-own.png';
import AccountIcon from "components/icon/AccountIcon";
import './assets/styles/CompletePage.scss';

const CompletePage = () => (
  <div className={'p-complete'}>
    <div className={'p-complete__header'}/>
    <Box
      title={'Activation complete'}
      image={<AccountIcon/>}
    >
      <div className="p-complete__text">
        <p>After you’ve downloaded the HUT app you can chat with the concierge, control smart features of your room and use services for housekeeping, breakfast and more.</p>
        <p>Don’t know your way around Amsterdam yet?! We’ve carefully curated a selection of the best hotspots in town and added all that goodness to the HUT app.</p>
      </div>
      <div className="p-complete__badges">
        <div/>
        <a href="https://itunes.apple.com/us/app/hut-amsterdam/id1279260501" alt="iOS AppStore Download">
          <img className="p-complete__badges__badge" src={appleStoreBadge} alt="iOS AppStore Download"/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=builders.are.we.hut" title="Google PlayStore Download">
          <img className="p-complete__badges__badge" src={googlePlayBadge} alt="Google PlayStore Download"/>
        </a>
        <div/>
      </div>
    </Box>
  </div>
);

export default CompletePage;